.gridStack {
    margin: auto;
    margin-bottom: 1em;
    justify-content: center;
    display: 'flex';
    align-items: center;
}

.cellBlink {
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
    animation-timing-function: linear;
}

@keyframes blink {
    0%   {opacity: 1;}
    50%  {opacity: 0.5;}
    100%  {opacity: 1;}
  }