.bmcImage {
  height: 18px;
  width: 26px;
  margin-bottom: 1px;
  margin-right: 6px;
  animation-name: shake;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  animation-timing-function: linear;
}

@keyframes shake {
  55% {transform: rotate(0deg); }
  58% { transform: rotate(-15deg); }
  61% { transform: rotate(15deg); }
  64% { transform: rotate(-15deg); }
  67% { transform: rotate(15deg); }
  70% { transform: rotate(-15deg);  }
  73% { transform: rotate(15deg);  }
  76% { transform: rotate(0deg);  }
}

.bmcArrow {
  height: 18px !important;
  width: 18px !important;
  margin-left: 4px;
}

.statsData {
  text-align: center;
}

.statsLink{
  text-align: right;
  float: right;
}

.statsSwitch{
  float: right;
  margin-top: -5px;
}

.centerItem {
  float: center;
}

.itemPaddingBottom {
  padding-bottom: 1em;
}

.itemPaddingTop {
  padding-top: 1em;
}

.itemPadding {
  padding-top: 1em;
  padding-bottom: 1em;
}